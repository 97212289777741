<template>
  <div v-if="isShow">
    <Loading/>
  </div>
<div class="common-bg">
  <div class="middle">
     <h1 class="common-h1">關於我們</h1>

<!--  01  -->
    <div class="in-about">
      <div class="in-about-left">
        <div class="in-title">
          <span>平臺介紹</span>
          <div class="line"></div>
        </div>
        <div class="in-editor">
          <Editor :content="introTxt1"></Editor>
        </div>
      </div>
      <div class="in-about-right">
        <img :src="introImg1">
      </div>
    </div>


    <!--  02   -->
    <div class="common-padding-half">
      <div class="in-about in-about-2">
        <div class="in-about-right">
          <img :src="introImg2">
        </div>
        <div class="in-about-left">
          <div class="in-title">
            <span>平臺宗旨</span>
            <div class="line"></div>
          </div>
          <div class="in-editor">
            <Editor :content="introTxt2"></Editor>
         </div>
        </div>
      </div>
    </div>
  </div>


  <!--   03 -->
  <div class="about-3">
    <div class="middle common-padding-half">
      <div class="common-title">
        <div class="common-line"></div>
        <span>
          網站規則
        </span>
        <div class="common-line"></div>
      </div>

      <div class="editor about-3-txt">
        <Editor :content="rules"></Editor>
      </div>


    </div>
  </div>
</div>
</template>

<script>
import Editor from "@/components/Editor";
import Loading from "@/components/loading";
import {getAbout} from "@/network/main";
export default {
  name: "About",
  components:{
    Editor,
    Loading
  },
  data(){
    return{
      introImg1:'',
      introTxt1:'',
      introImg2:'',
      introTxt2:'',
      rules:'',
      isShow:true,
    }
  },
  created() {
    this.getAbout()
  },
  methods:{
    getAbout() {
      getAbout().then(res =>{
        this.isShow = false;
        this.introImg1 = res.data.data.platform_introduction_images;
        this.introTxt1 = res.data.data.platform_introduction;
        this.introImg2 = res.data.data.platform_aim_images;
        this.introTxt2 = res.data.data.platform_aim;
        this.rules = res.data.data.website_rules;
      })
    },
  }
}
</script>

<style scoped>

</style>